html {
    height: -webkit-fill-available;
}

.App {
  text-align: left;
}

.App-logo {
  width: 175px;
  pointer-events: none;
  margin-bottom: 2vmin;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: #161d23;
  padding: 5%;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

h1, h2 {
    padding: 0px 22px 0px 22px;
    margin-bottom: 2vmin;
    line-height: 160%;
}
h1 {
    font-weight: bold;
    font-size: 2rem;
}
h2 {
    font-weight: normal;
    font-size: 1rem;
}
.Bold {
    font-weight: bold;
}
a {
    color: blue;
    text-decoration: none;
}
a.visited {
    color: blue;
}
a.hover {
    text-decoration: underline;
}
